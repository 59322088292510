import ApiService from "@/common/api.service";

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  async upload() {
    const data = new FormData();
    data.append("image", await this.loader.file);
    // data.append("type", "img");
    const res = await ApiService.post("images", data);
    return {
      default: res.url
    };
  }
}

export default MyUploadAdapter;
