<template>
  <ckeditor5
    @ready="onReady"
    :editor="editor"
    :config="{ placeholder: placeholder, ...editorConfig }"
    :value="value || ''"
    @input="value => $emit('input', value)"
  />
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import List from "@ckeditor/ckeditor5-list/src/list";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import EditorImgUpload from "@/apis/EditorImgUpload.js";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import FileRepository from "@ckeditor/ckeditor5-upload/src/filerepository";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";

export default {
  data() {
    return {
      editor: ClassicEditor,
      myPlaceholder: "",
      editorConfig: {
        plugins: [
          Indent,
          IndentBlock,
          Essentials,
          Alignment,
          Bold,
          Italic,
          Underline,
          Subscript,
          Superscript,
          List,
          Image,
          ImageStyle,
          ImageResize,
          ImageToolbar,
          LinkImage,
          ImageUpload,
          FileRepository,
          Paragraph
        ],
        toolbar: {
          items: [
            "bold",
            "italic",
            "underline",
            "subscript",
            "superscript",
            "|",
            "alignment",
            "|",
            "outdent",
            "indent",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "imageUpload"
          ]
        },
        image: {
          toolbar: ["imageStyle:full", "imageStyle:side"]
        },
        language: "en"
      }
    };
  },
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    testType: {
      type: String,
      default: "sat"
    },
    height: {
      type: String,
      default: "auto"
    }
  },
  watch: {
    placeholder(value) {
      console.log(value);
    }
  },
  computed: {},
  methods: {
    onReady(editor) {
      // 自定义上传图片插件
      editor.plugins.get("FileRepository").createUploadAdapter = loader => {
        return new EditorImgUpload(loader);
      };
      editor.editing.view.change(writer => {
        writer.setStyle(
          "height",
          `${this.height}px`,
          editor.editing.view.document.getRoot()
        );
      });
    }
  }
};
</script>
<style>
.ck.ck-editor {
  line-height: normal !important;
}
.ck.ck-placeholder:before,
.ck .ck-placeholder:before {
  white-space: pre-line;
  opacity: 0.3;
}
</style>
